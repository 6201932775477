import ReactGA from 'react-ga4'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchArticleBySlug } from '../services/articleSevices'
import { Typography,Box, Skeleton } from '@mui/material'
import Error from './Error'
import Article from './Article'
import usePageTracking from '../services/usePageTracking'
const ArticleDetail = () => {
    usePageTracking()
  // useEffect(() => {
  //   ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/articles/:slug',
  //     title: 'Specific Article Detail Page',
  //   })
  // })
  const { slug } = useParams()
  const [article, setArticle] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getArticle = async () => {
      try {
        const data = await fetchArticleBySlug(slug)
        setArticle(data)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    getArticle()
  }, [slug])

  if (loading) {
    // return <Typography>Loading123...</Typography>
    return (
      <Box sx={{ padding: 2 }}>
        <Skeleton variant='text' width='80%' height={40} />
        <Skeleton
          variant='text'
          width='60%'
          height={30}
          sx={{ marginTop: 2 }}
        />
        <Skeleton
          variant='rectangular'
          width='100%'
          height={400}
          sx={{ marginTop: 2 }}
        />
      </Box>
    )
  }
  if (error) {
    return <Error error={error} />
  }

  return article && <Article article={article} />
}
export default ArticleDetail
// <Paper sx={{ margin: 2, padding: 2 }} id={`article-${article.id}`}>
//     <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
